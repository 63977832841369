import { get, isEmpty, keyBy, map } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Theme, Table, TH, TD, TR, Button, ShowPopup, Popup } from '@commonsku/styles';
import { oauth } from '../utils';

const DNSRecordsPopup = ({ company_email_domain, onClose }) => {
  const { dkim, email_domain } = company_email_domain;

  return <Popup title="DNS records" onClose={onClose}>
    <Table>
      <TR>
        <TH>Type</TH>
        <TH>Name</TH>
        <TH>Value</TH>
      </TR>
      {map(get(dkim, 'DkimTokens'), (token, j) => {
        return <TR key={j}>
          <TD>CNAME</TD>
          <TD>{`${token}._domainkey.${email_domain}`}</TD>
          <TD>{token}.dkim.amazonses.com</TD>
        </TR>;
      })}
    </Table>
  </Popup>;
};

const CompanyEmailDomainTable = ({ company_id, company_type }) => {
  const [ companyEmailDomains, setCompanyEmailDomains ]  = useState();
  useEffect(() => {
    oauth('GET', 'company-email-domain', { company_id, company_type, include: ['dkim'] }).then((response) => {
      setCompanyEmailDomains(keyBy(get(response, 'json.company_email_domains'), 'company_email_domain_id'));
    });
  }, [company_id, company_type]);
  return <Theme>
    <Table>
      <TR>
        <TH>Email Domain</TH>
        <TH>DKIM Signatures</TH>
        <TH>Publish DNS records</TH>
        <TH>Verification Status</TH>
        <TH></TH>
      </TR>
      {map(companyEmailDomains, (company_email_domain, i) => {
        const { company_email_domain_id, company_type, email_domain, dkim } = company_email_domain;
        const verifyDomain = () => {
          return oauth('PUT', `company-email-domain/${company_email_domain_id}`, {
            actionName: 'verify', company_type
          })
          .then(({ json }) => {
            setCompanyEmailDomains({
              ...companyEmailDomains,
              [company_email_domain_id]: json.company_email_domain,
            });
            return json.company_email_domain;
          });
        };
        return <TR key={i}>
          <TD>{email_domain}</TD>
          <TD>{get(dkim, 'DkimEnabled') ? 'Enabled' : 'No'}</TD>
          <TD>
            <ShowPopup
              popup={DNSRecordsPopup}
              company_email_domain={company_email_domain}
              verifyDomain={verifyDomain}
              render={({ onClick }) => {
                return <a onClick={() => {
                  const tokens = get(company_email_domain, 'dkim.DkimTokens');
                  if (isEmpty(tokens)) {
                    verifyDomain();
                  }
                  onClick();
                }}>View DNS records</a>;
              }}
            />
          </TD>
          <TD>{get(dkim, 'DkimVerificationStatus', '')}</TD>
          <TD>{
            ['Failed', 'TemporaryFailure', 'NotStarted', 'Pending'].indexOf(get(dkim, 'DkimVerificationStatus', '')) >= 0 &&
            <Button onClick={verifyDomain}>Retry</Button>
          }</TD>
        </TR>;
      })}
    </Table>
  </Theme>;
};

export default CompanyEmailDomainTable;
